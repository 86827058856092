/* Cards
   ========================================================================== */

$kanban-success: #ccffde;
$kanban-alert: #fff3cb;
$kanban-danger: #FF5252;
$kanban-warning: #FFEB3B;

.card-list {
  padding-top: rem(25);
  padding-right: rem(20);
}

.card-header {
  background: transparent;
  border-bottom: none;
  @include py(rem(10), 0);
  @include px(rem(10), rem(10));
}

.card-footer {
  background: transparent;
  border-top: none;
  @include py(0, rem(10));
  @include px(rem(10), rem(10));
}

.card-title {
  @include font($size: rem(15), $weight: $fw-medium);
  margin-bottom: rem(5);
  color: rgba($color: $k-color, $alpha: 0.7);
}

.card-subtitle {
  @include font($size: rem(12), $weight: $fw-light);
  color: rgba($color: $k-color, $alpha: 0.5);
}

.card-text {
  @include font($size: rem(11), $weight: $fw-regular);
  color: rgba($color: $k-color, $alpha: 0.5);
}

.card-link {
  @include font($size: rem(11), $weight: $fw-medium);
  color: $s-color;
  text-decoration: underline;

  &:hover {
    color: $s-color;
    opacity: 0.8;
  }
}

.card-body {
  @include px(rem(10), rem(10));
  @include py(rem(10), rem(10));

  .card--form & {
    @include px(rem(15), rem(15));
    @include py(rem(10), rem(6));
    color: $f-color;
  }

  .card--form-group & {
    @include px(0, 0);
    @include py(0, 0);
    color: $f-color;
  }
}

.card--kanban {
  border: transparent;
  margin-bottom: rem(10);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);

  &.is-success {
    background: $kanban-success;
  }

  &.is-alert {
    background: $kanban-alert;
  }

  &.is-selected {
    background: #e2ebe5;
    border: 1px solid $s-color;
  }

  &.is-timeline {
    background: #fafafa;
  }

  &.is-danger {
    box-shadow: 0px 0px 4px $kanban-danger;
  }

  &.is-warning {
    box-shadow: 0px 0px 4px $kanban-warning;
  }

  .btn {
    height: rem(23);
    padding: 0;
    @include px(rem(6), rem(6));
    @include flex($display: inline-flex, $a-items: center, $j-content: center);
    @include font($size: rem(10), $weight: $fw-medium);

    &__img {
      width: rem(10);
      margin-right: rem(5);
    }
  }
}

.card--form,
.card--dropdown {
  //min-height: 100%;
  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(13);
    width: rem(13);
    flex-shrink: 0;
    background: $b-color;
    border-radius: 100px;
    position: absolute;
    right: -5px;
    top: -5px;
    color: $m-color;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}

.card-dropdown-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.card-dropdown {
  color: $f-color;
  display: none;
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 0;
  right: 0;

  .list-group {
    &.is-scroll {
      max-height: 12.5rem;
      overflow-y: auto;
      position: sticky;
    }
  }

  .list-group-item {
    @include all-trans;
    cursor: pointer;
    @include px(rem(15), rem(15));
    @include py(rem(5), rem(5));

    &:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &:hover {
      background: rgb(250, 250, 250);
    }

    a {
      font-size: rem(12);
      color: $f-color;
      text-decoration: none;
    }
  }

  .is-active & {
    height: 100%;
    display: block;
  }

  &__arrow {
    width: rem(24);
    height: rem(24);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    img {
      @include all-trans;
      transform: rotate(180deg);

      .is-active & {
        transform: rotate(0);
      }
    }
  }

  &__label {
    flex-grow: 1;
  }

  &__footer {
    @include px(rem(15), rem(15));
    @include py(rem(10), rem(10));
    @include font($size: rem(10), $weight: $fw-bold, $color: $p-color);
    position: absolute;
    /* top: 12.5rem; */
    left: 0;
    right: 0;
    background: $b-color;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &__link {
    color: $s-color;
    text-decoration: none;
    @include all-trans;

    &:hover {
      color: $s-color;
      opacity: 0.8;
      text-decoration: none;
    }
  }
}

.card-danger {
  color: $danger-color;
  font-size: rem(11);
  display: flex;
  align-items: center;
  white-space: nowrap;

  &__icon {
    margin-right: 6px;
  }
}

.is-danger {
  border: 1px solid $kanban-danger !important;
}

.is-warning {
  border: 1px solid $kanban-warning !important;
}
