/* Wrapper
   ========================================================================== */
@use "sass:math";

.wrapper {
  height: 100%;
}

.content-wrapper {
  padding-left: $sidebar;
  position: relative;
  overflow-x: auto;
  transition: padding-left 0.1s;
  height: 100%;

  @include respond(l-desktop) {
    @include scrollbar;

    &::-webkit-scrollbar {
      height: 8px;
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: $m-color;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $grey-d;
    }
  }

  .sidebar--collapsed & {
    padding-left: rem(80);
  }
}

.content {
  padding-top: rem(10);
  padding-bottom: rem(10);

  @include px(rem(24), rem(24));

  .sidebar--collapsed & {
    margin-right: 0;
  }

  &--map {
    margin-left: -1.5rem;
    width: calc(100% + 3rem);
    height: calc(100vh - 7.5rem);

    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}

.content-login {
  min-height: 100%;
  position: relative;
  @include px(rem(15), rem(15));
  margin-right: math.div($sidebar-login, 2);
  background: $grey-c;

  @include respond(desktop) {
    margin-right: $sidebar-login;
  }

  &.is-inverse {
    margin-left: math.div($sidebar-login, 2);
    margin-right: initial;

    @include respond(desktop) {
      margin-left: $sidebar-login;
    }
  }
}

.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  @include respond(l-desktop) {
    @include scrollbar;

    &::-webkit-scrollbar {
      height: 8px;
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: $m-color;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $grey-d;
    }
  }

  .content {
    flex: 0 0 auto;
    display: flex;
  }
}

.scrolling-wrapper {
  -webkit-overflow-scrolling: touch;
}

.scrolling-wrapper {
  &::-webkit-scrollbar {
    display: none;
  }
}

.col-cs-250 {
  width: rem(270);
}
