/* Fonts
   ========================================================================== */

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
   font-family: 'icomoon';
   src: font-url('icomoon.eot?8na3my');
   src: font-url('icomoon.eot?8na3my#iefix') format('embedded-opentype'),
      font-url('icomoon.ttf?8na3my') format('truetype'),
      font-url('icomoon.woff?8na3my') format('woff'),
      font-url('icomoon.svg?8na3my#icomoon') format('svg');
   font-weight: normal;
   font-style: normal;
   font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
   /* use !important to prevent issues with browser extensions that change fonts */
   font-family: 'icomoon' !important;
   speak: none;
   font-style: normal;
   font-weight: normal;
   font-variant: normal;
   text-transform: none;
   line-height: 1;

   /* Better Font Rendering =========== */
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
   content: "\e900";
 }
 .icon-arrow-left:before {
   content: "\e901";
 }
 .icon-arrow-right:before {
   content: "\e902";
 }
 .icon-arrow-top:before {
   content: "\e903";
 }
 .icon-bus:before {
   content: "\e904";
 }
 .icon-card-alert:before {
   content: "\e905";
 }
 .icon-check:before {
   content: "\e906";
 }
 .icon-circle:before {
   content: "\e907";
 }
 .icon-close:before {
   content: "\e908";
 }
 .icon-collapse:before {
   content: "\e909";
 }
 .icon-dot:before {
   content: "\e90a";
 }
 .icon-download:before {
   content: "\e90b";
 }
 .icon-edit:before {
   content: "\e90c";
 }
 .icon-exchange:before {
   content: "\e90d";
 }
 .icon-expand:before {
   content: "\e90e";
 }
 .icon-file-plus:before {
   content: "\e90f";
 }
 .icon-filter:before {
   content: "\e910";
 }
 .icon-front:before {
   content: "\e911";
 }
 .icon-help:before {
   content: "\e912";
 }
 .icon-login:before {
   content: "\e913";
 }
 .icon-logout:before {
   content: "\e914";
 }
 .icon-map .path1:before {
   content: "\e915";
   color: rgb(0, 0, 0);
 }
 .icon-map .path2:before {
   content: "\e916";
   margin-left: -1em;
   color: rgb(255, 255, 255);
 }
 .icon-menu:before {
   content: "\e917";
 }
 .icon-minus:before {
   content: "\e918";
 }
 .icon-money:before {
   content: "\e919";
 }
 .icon-nep:before {
   content: "\e91a";
 }
 .icon-pin:before {
   content: "\e91b";
 }
 .icon-plus:before {
   content: "\e91c";
 }
 .icon-search:before {
   content: "\e91d";
 }
 .icon-settings:before {
   content: "\e91e";
 }
 .icon-statistics:before {
   content: "\e91f";
 }
 .icon-tag:before {
   content: "\e920";
 }
 .icon-tool:before {
   content: "\e921";
 }
 .icon-trash:before {
   content: "\e922";
 }
 .icon-upload:before {
   content: "\e923";
 }
 .icon-web:before {
   content: "\e924";
 }
