/* Sideoffset
   ========================================================================== */

.sideoffset {
  background: $b-color;
  @include position($position: fixed, $top: 0, $bottom: 0, $right: 0, $left: 0);
  width: 100%;
  height: 100%;
  z-index: 1040;
  padding: rem(15);
  padding-bottom: 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  &.d-out {
    -webkit-animation-name: fadeOutRightBig;
    animation-name: fadeOutRightBig;
  }

  @include respond(phone) {
    top: 0;
    right: 0;
    left: initial;
    width: rem(420);
  }

  &__container--fluid {
    width: 100%;
    height: 100%;
    @include flex($display: flex, $direction: column);
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__header {
    margin-top: 2em;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
  }

  &__close {
    width: rem(15) !important;
    height: rem(15) !important;
    flex-shrink: 0;
    cursor: pointer;
    color: $m-color !important;
    padding: 0 !important;
    &:hover {
      opacity: 0.8;
    }
  }
}
