
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$md-fimmprimary: (
  50 : #e8e8ea,
  100 : #c6c5c9,
  200 : #a09ea6,
  300 : #7a7782,
  400 : #5e5967,
  500 : #413c4c,
  600 : #3b3645,
  700 : #322e3c,
  800 : #2a2733,
  900 : #1c1a24,
  A100 : #906bff,
  A200 : #6a38ff,
  A400 : #4405ff,
  A700 : #3b00eb,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #ffffff,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);

$md-fimmaccent: (
  50: #fefbe6,
  100: #fdf5c2,
  200: #fbee9a,
  300: #f9e773,
  400: #f8e256,
  500: #f7de3b,
  600: #f4cc36,
  700: #f1b530,
  800: #ee9d29,
  900: #e7761e,
  A100 : #ffffff,
  A200 : #edbe1d,
  A400 : #fff2bd,
  A700 : #ffeca3,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #000000,
      600 : #000000,
      700 : #000000,
      800 : #000000,
      900 : #000000,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette($md-fimmprimary);


$app-accent: mat.define-palette($md-fimmaccent, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$fimmapp-theme: mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  ),
  typography: mat.define-typography-config(),
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($fimmapp-theme);

@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'libs/fe/ui-ng/scss/abstracts/variables.scss';
@import './styles/variables';
@import './styles/color';

@import 'libs/fe/ui-ng/scss/style.scss';
html, body { height: 100%; }
body { margin: 0; font-family: Inter, "Helvetica Neue", sans-serif; }
